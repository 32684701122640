import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useFilterContext } from '@context';
import { RefinementOption } from '@components/search';
import { formatCurrency } from '@utils/shopify';
import { LOCALE_CODES, LOCALE_DICT } from '@constants';
import { PRICE_RANGES } from '@utils/algolia';
import RefinementListAccordion from '../RefinementListAccordion';

const PriceRangeCheckboxes = ({ onChange, locale }) => {
	const [selectedRanges, setSelectedRanges] = useState([]);

	const handleCheckboxChange = range => {
		const isSelected = selectedRanges.some(r => r.min === range.min && r.max === range.max);

		const newSelectedRanges = isSelected
			? selectedRanges.filter(r => r.min !== range.min || r.max !== range.max)
			: [...selectedRanges, range];

		setSelectedRanges(newSelectedRanges);
		onChange(newSelectedRanges);
	};

	return (
		<RefinementListAccordion
			refinementOptions={PRICE_RANGES.map(range => {
				const currencyCode = LOCALE_DICT[locale].currencyCode;
				const showCurrencyCode = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
				const label = `${formatCurrency({ amount: range.min, locale, currencyCode })}${
					range.max
						? ` - ${formatCurrency({ amount: range.max, locale, currencyCode }, showCurrencyCode)}`
						: `+ ${showCurrencyCode ? currencyCode : ''}`
				}`;

				return (
					<RefinementOption
						key={'price-' + range.min + '-' + range.max}
						isRefined={selectedRanges.some(r => r.min === range.min && r.max === range.max)}
						label={label}
						type={'price'}
						refine={() => handleCheckboxChange(range)}
						value={label}
					/>
				);
			})}
			isInitiallyOpened={false}
			attribute='Price'
			type='collections'
		/>
	);
};

const RefinementPrice = () => {
	const { setPriceFilter } = useFilterContext();
	const { locale } = useRouter();

	const handleRangeChange = ranges => {
		let priceFilter = '';
		const priceLabel =
			locale === LOCALE_CODES.US ? 'price' : `market_pricing_${LOCALE_DICT[locale].currencyCode.toLowerCase()}.price`;
		ranges.forEach(range => {
			if (!!priceFilter) {
				priceFilter += ' OR ';
			}
			if (range.max) {
				priceFilter += `( ${priceLabel}: ${range.min} TO ${range.max} )`;
			} else {
				priceFilter += `( ${priceLabel} >= ${range.min} )`;
			}
		});
		setPriceFilter(priceFilter);
	};

	return (
		<>
			<PriceRangeCheckboxes onChange={handleRangeChange} locale={locale} />
		</>
	);
};

export default RefinementPrice;
