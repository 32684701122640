import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Filter = ({ color, ...props }) => {
	return (
		<AccessibleIcon.Root label='filter'>
			<svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M4.75 8H7.25' stroke={color} strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
				<path d='M15 8L19 8' stroke={color} strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
				<path d='M5 16H10' stroke={color} strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
				<path d='M17.75 16H19.25' stroke={color} strokeWidth='1.5' strokeLinecap='square' strokeLinejoin='round' />
				<path
					d='M12.25 8C12.25 9.24264 11.2426 10.25 10 10.25C8.75736 10.25 7.75 9.24264 7.75 8C7.75 6.75736 8.75736 5.75 10 5.75C11.2426 5.75 12.25 6.75736 12.25 8Z'
					stroke={color}
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M17.25 16C17.25 17.2426 16.2426 18.25 15 18.25C13.7574 18.25 12.75 17.2426 12.75 16C12.75 14.7574 13.7574 13.75 15 13.75C16.2426 13.75 17.25 14.7574 17.25 16Z'
					stroke={color}
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Filter;
